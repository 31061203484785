import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { funcDropdown } from '../../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import MHistoryHome from './medicalhistory/mhistoryHome'
import { NavMenu } from '../navMenu';
import Dashboard from './medicalhistory/dashboardhome'
import BackIcon from '../../../img/back1.svg'
import PatientCalendar from './patientCalendar'

const menus = [
    {
        to: "/site/patient/dashboard",
        text: "Dashboard"
    },
    {
        to: "/site/patient/details",
        text: "Details"
    },
    {
        to: "/site/patient/medicalhistory",
        text: "Medical History"
    },
    {
        to: "/site/patient/audittrail",
        text: "Audit Trail"
    },
    {
        to: "/site/patient/messages",
        text: "Messages"
    },
    {
        to: "/site/patient/calendar",
        text: "Appointments"
    }
]

const PatientHome = (props) => {
    useEffect(() => {
        props.UpdateFormField({ name: "hidestudytab", value: false })
        return () => {
            props.UpdateFormField({ name: "hidestudytab", value: true })
            props.UpdateFormField({ name: "newpirPatientsToken", value: false })
        }
    }, [])


    const LoadCom = (props) => {
        //console.log(props.fileName)
        let Component = Loadable({
            loader: () => import(`./${props.fileName}`),
            loading() {
                return <div className="fullScreen">
                    <CircularProgress
                        disableShrink
                        className="progressCircle"
                        value={90}
                        size={100}
                        thickness={1.5}
                    />
                    <span>UI Script loading</span>
                </div>
            },
        });
        return <Component {...props} />
    }

    const goBack = (event) => {
        event.preventDefault();
        if (props.formValues.pirpatienttoken) {
            props.history.push('/site/study/pir/patientdetail')
            props.UpdateFormField({ name: "pirpatienttoken", value: false })
        } else if (props.formValues.patientRequestToken) {
            props.history.push('/site/patientrequests')
            props.UpdateFormField({ name: "patientRequestToken", value: false })
        } else if (props.formValues.studyPatientsToken) {
            props.history.push('/site/studypatients')
            props.UpdateFormField({ name: "studyPatientsToken", value: false })
        }else if(props.formValues.newpirPatientsToken){
            props.history.push('/site/newpir/searchcriteria')
            props.UpdateFormField({ name: "newpirPatientsToken", value: false })
        }
        else {
        props.history.push('/site/patients')
}
    }
return (
    <>
        <div className="d-flex mb-3 justify-content-end">
            <h1 className="mr-auto primary-header">{props.formValues.patientdata.firstName ? props.formValues.patientdata.firstName + ' ' + props.formValues.patientdata.lastName : props.formValues.patientdata.patientFirstName+ ' ' + props.formValues.patientdata.patientLastName}</h1>
            {/* <button  className="btn btn-dnger btn-rounded" >
                    <img src={BackIcon} alt="goback" /> BACK</button> */}
            <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={goBack}
            // style={{position:"fixed",zIndex:"9999"}}
            >BACK</button>
        </div>
        <div className="">


        </div>

        <section className="">
            <NavMenu menus={menus} />
        </section>

        <div className="">
            <Switch>
                <Route path="/site/patient/dashboard" component={Dashboard} />
                <Route path="/site/patient/details">
                    <LoadCom fileName="patientdetails" />
                </Route>
                <Route path="/site/patient/medicalhistory" component={MHistoryHome}>
                    {/* < MHistoryHome/> */}
                    {/* <LoadCom fileName="medicalhistory/mhistoryHome" /> */}
                </Route>
                <Route path="/site/patient/audittrail">
                    <LoadCom fileName="patientAuditTrail" />
                </Route>
                <Route path="/site/patient/messages">
                    <LoadCom fileName="messages" />
                </Route>
                <Route path="/site/patient/calendar" component={PatientCalendar}>
                    {/* <LoadCom fileName="selectPatients" /> */}
                </Route>
                <Redirect to="/site/patient/dashboard" />
            </Switch>
        </div>
    </>
)
}
const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientHome);



