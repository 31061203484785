import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DragandDrop = ({ data, header,subheader, handleSubmit,colors }) => {
  console.log(data, "tunnel")
  const [assessments, setAssessments] = useState(data ? data : [])
  const [changed, setChanged] = useState(false)
  // /drag n drop 

  const id2List = {
    droppable: assessments,

  };

  const getList = id => id2List[id];

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      // if (source.droppableId === 'droppable2') {
      //   setSelectedList(items);
      // } else {
      setAssessments(items)
      setChanged(true)
      // }


      // } else {
      //   const result = move(
      //     getList(source.droppableId),
      //     getList(destination.droppableId),
      //     source,
      //     destination
      //   );
      //   setAssessments(result.droppable)
      //   setSelectedList(result.droppable2);
    }
  };

  return (
    <>
      <div className="panel-primary-wrap">
        <div className="row">

          <DragDropContext onDragEnd={onDragEnd}>
            <div className="col-md-12" >
              <div className='mb-2'>
                <span className="secondary-header mr-auto">{header}</span> <span style={{fontSize:"0.8rem",color:"#666"}}>{subheader}</span>
              </div>

              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    className="fixedWidthFunnelCriteria"
                  >
                    {assessments && assessments.map((item, index) => (
                      <Draggable
                        key={item[3]}
                        draggableId={item[3]}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          > <div className="card" style={{ marginBottom: "3px" }}>
                             <div className="card-body" style={{ padding: "1rem", cursor: "move" }}>
                             <div style={{backgroundColor:colors[index],height:"10px",width:"10px",borderRadius:"10px",display: "inline-block", marginRight:"7px"}}></div> {(item[4]==="I" ? "IC" :"EC") +": "+item[0].substring(0, item[0].lastIndexOf("(")) + " (Match " + item[1] + ")"} 
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>

                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>


          </DragDropContext>
          <div className="col-md-12">
            <div className="buttonContainer" style={{ float: "right", marginTop: "1rem" }}>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={(e) => handleSubmit(e, assessments)} disabled={!changed}>Submit</button>
              {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DragandDrop