import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import SmallLogo from '../../../img/hekma-logo.svg';
import UserProfile from '../../../img/user-profile.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserMenu from './userMenu';
import Calendar from '../../../img/calendar.svg'
import ReqAppIcon from '../../../img/Request-Appointment.png'
import { oldgenericApi } from '../../../common/apiconfig'
import moment from 'moment'
import { Paginationn } from '../../../sponsorApp/common/pagination'
import Tooltip from '@material-ui/core/Tooltip';
import Alerts from './alerts'
const Header = (props) => {
  const [data, setData] = useState('')
  const [offset, setoffset] = useState(0)
  const [NRSData, setNRSData] = useState(0)
  const [offsetReq, setoffsetReq] = useState(0)
  const [activeNSR, setActiveNSR] = useState("")
  const [queriesData, setQueriesData] = useState("")
  const [activeApp, setActiveApp] = useState('')
  const location = props.location;
  let roleInfo = JSON.parse(localStorage.getItem('roles'))

  useEffect(() => {
    if (location.pathname == "/activateContact" || location.pathname == "/activatePatient") {
    } else {
      fetchAlerts()
      fetchNonRegStudyReq()
      fetchQueries()
      const interval = setInterval(() => {
        fetchAlerts()
        fetchNonRegStudyReq()
        fetchQueries()
      }, 300000);
      return () => clearInterval(interval);
    }
  }, [])
  const searchSubmit = (event) => {
    event.preventDefault();
    let value = document.querySelector("input[name='headerSearch']").value;
    if (value.length) {
      props.updateHeaderSearchValue({ value });
      props.history.push('/site/results');
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    props.history.push('/siteselection')
  }
  const openCalendar = (e) => {
    e.preventDefault()
    props.history.push('/site/patientappointments')
  }

  const openPatient = async (e, data) => {
    e.preventDefault();
    if (data.requestStatus === "Created") {
      let requestBody = `
        mutation 
        {
            updateRequest(requestId:"${data.requestId}",requestStatus:"Viewed",
            requestResponse:"I have sent a schedule") {
            code
            type
            message
            attributes
          }  
        }
            `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('updateRequest', requestBody);
      // setProgress(false);

      if (res && res.data.updateRequest.code === '200') {
        fetchAlerts()
      } else {
        console.log('Error on response!')
      }

    }
    props.history.push('/site/patientrequests')
  }

  const openNRSItem = async (e, data) => {
    e.preventDefault()
    await props.setSidebarActiveEl('trailmatch');
    props.history.push('/site/trailmatch/nonRegisteredstudies')
  }

  const openQueryPage=(e,data)=>{
    e.preventDefault()
    // props.history.push('/site/site/study/queries')
  }

  const fetchAlerts = async () => {
    let requestBody = {
      query: `query
          {
            searchRequest(fromKey:"*",fromType:"*",          toKey:"${localStorage.getItem('loginUserId')}",toType:"doctor",          refKey1:"*",refType1:"*",          refKey2:"",refType2:"*",          refKey3:"*",refType3:"*",          type:"Doctor Appointment"   
            ) {
                requestId
                fromKey
                fromType
                toKey
                toType
                refKey1
                refType1
                refKey2
                refType2
                refKey3
                refType3
                type
                requestTimestamp
                requestDescription
                requestStatus
                requestResponse
                requestUpdateTimestamp
                createdBy
                createdTs
              }
            }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('searchRequest', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.searchRequest != null && res.data.searchRequest.length) {
        setData(res.data.searchRequest)
        var activeApp = res.data.searchRequest.filter(v => v.requestStatus === "Created")
        setActiveApp(activeApp)
        props.UpdateFormField({ name: "patientrequests", value: res.data.searchRequest })
      } else {
        setData([])
      }
    }
  }
  const fetchNonRegStudyReq = async () => {
    let requestBody = {
      query: `query {
        getOpenSquareLeadRequests(siteId:"${props.formValues.autosite.sponsorAccountId}",
        leadStatus:"*")
       {
        requestId
        requestNum
      accountId
      accountName
      inclusion
      exclusion
      reference
      studyId
      studyNum
      siteId
      siteName
      siteCity
      siteCountry
      studyStatus
      leadStatus
      requestDateTime
      responseDateTime
      patientCount
      price
      priceType
      totalPrice
      createdBy
      createdTs
      modifiedBy
      modifiedTs
        }
      }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('getOpenSquareLeadRequests', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.getOpenSquareLeadRequests != null && res.data.getOpenSquareLeadRequests.length) {
        setNRSData(res.data.getOpenSquareLeadRequests)
        var activeNSR = res.data.getOpenSquareLeadRequests.filter(v => v.leadStatus === ("New" || "Update"))
        setActiveNSR(activeNSR)

      } else {
        setData([])
      }
    }
  }
  const fetchQueries = async () => {
    let requestBody = {
      query: `query {
          searchStudySiteQuery(queryId:"*",queryNum:"*",studyId:"*",siteId:"${props.formValues.autosite.sponsorAccountId}",statuses:"Open,ReOpen",assignedTo:"${localStorage.getItem('loginUserId')}") {
            queryId
      queryNum
      studyId
      studyName
      siteId
      status
      assignedTo
      assignedToName
      queryTitle
      queryDescription
      resolvedDate
      category
      severity
      queryResponse
      sponsorReference1
      sponsorReference2
      siteReference1
      siteReference2
      createdBy
      createdByName
      createdTs
      modifiedBy
      modifiedTs
        }
      }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('searchStudySiteQuery', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.searchStudySiteQuery != null && res.data.searchStudySiteQuery.length) {
        setQueriesData(res.data.searchStudySiteQuery)
      } else {
        setQueriesData([])
      }
    }
  }
  const handlePageClick = (e, offset) => {
    e.stopPropagation()
    setoffset(offset)
  }

  const handlePageClickReq = (e, offset) => {
    e.stopPropagation()
    setoffsetReq(offset)
  }

  return (
    <>

      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <nav className="navbar navbar-expand navbar-light bg-header-shadow topbar static-top">
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2">
              <i className="fa fa-bars"></i>
            </button>
            <a href="#" className="ml-xs-1 ml-md-3 mr-xs-2 mr-md-5" onClick={handleClick}><img src={SmallLogo} alt="logo-small" /></a>
            {/* <!-- Topbar Search --> */}
            {/* {
                            props.formValues.globalsearch &&
                            <form noValidate autoComplete="off" onSubmit={searchSubmit} className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group globalsearch">
                                    <input type="text" className="form-control" placeholder="Search Studies" aria-label="Search Studies" aria-describedby="button-addon2" name="headerSearch" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary-blue inputSearchBtn" type="submit" id="button-addon2"><i className="fa fa-search fa-sm"></i> Search</button>
                                    </div>
                                </div>
                            </form>
                        } */}
            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto">
              {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
              <li className="nav-item dropdown no-arrow d-sm-none">
                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-search fa-fw"></i>
                </a>
                {/* <!-- Dropdown - Messages --> */}
                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                      <input type="text" className="form-control bg-light border-0 small" placeholder="Search Studies" aria-label="Search" aria-describedby="basic-addon2" />
                      <div className="input-group-append">
                        <button className="btn btn-primary-blue" type="button">
                          <i className="fa fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              {/* <!-- Nav Item - Alerts --> */}
              {
                window.location.pathname != '/site/siteselection' &&  <>
                {roleInfo.role !== "CliniOps_TM_SysAdmin" && <>
                  <li className="nav-item dropdown no-arrow" >
                    <a className="nav-link" href="#" onClick={(e) => openCalendar(e)}>
                      <Tooltip title="Calendar" arrow><img src={Calendar} alt="calendar" width="20px" /></Tooltip>
                      {/* <span className="badge badge-danger badge-counter">10</span> */}
                    </a>
                  </li>
                  <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      <Tooltip title="Alerts" arrow><i className="far fa-bell fa-fw fa-lg"></i></Tooltip>
                      <span className="badge badge-danger badge-counter"> {(activeNSR.length > 0 || activeApp.length > 0 || queriesData.length > 0) ? (activeNSR.length + activeApp.length + queriesData.length) == 0 ? "" : activeNSR.length + activeApp.length + queriesData.length : ""}</span>
                    </a>

                    <form id="form" className="dropdown-menu dropdown-menu-right shadow animated--grow-in alertCss cr" aria-labelledby="alertsDropdown ">
                      <Alerts data={data} openPatient={openPatient} handlePageClick={handlePageClick} offset={offset} activeApp={activeApp}
                        handlePageClickReq={handlePageClickReq} offsetReq={offsetReq} NRSData={NRSData}
                        openNRSItem={openNRSItem} activeNSR={activeNSR} queriesData={queriesData} openQueryPage={openQueryPage}
                      />
                    </form >
                  </li>
</>}

                  {/* <!-- Nav Item - User Information --> */}
                  <li className="nav-item dropdown">
                    {/* <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="img-profile rounded-circle mr-md-2 mr-xs-0" src={UserProfile} alt="user-profile" />
                                <span className=" d-none d-lg-inline user-text">Admin</span>
                            </a>
                            
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <a className="dropdown-item">
                                <i className="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a>
                            </div> */}
                    {/* {props.formValues.autosite && props.formValues.autosponsor && <>
                                    <UserMenu />
                                   
                                </>} */}

                    <UserMenu />


                  </li>
                </>
              }
              <li> </li>
            </ul>
          </nav>
          {/* <!-- End of Topbar --> */}
        </div>
        {/* <!-- End of Main Content --> */}

      </div>
      {/* <!-- End of Content Wrapper --> */}

    </>

  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

