import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import VariablePiechart from '../../../siteSrc/components/site/pir/dashboard/variablepiechart'
import Donut from '../../../siteSrc/components/site/pir/dashboard/3ddonut'
import Columnchart from '../../../siteSrc/components/site/pir/dashboard/3dColumn'
import Piechart from '../../../siteSrc/components/site/pir/dashboard/piechart'
import BulletChart from '../../../siteSrc/components/site/pir/dashboard/bulletgraph'
import Bubblechart from '../../../siteSrc/components/site/pir/dashboard/bubblechart'
import Maps from '../../../siteSrc/components/site/pir/dashboard/map'
import { genericApi, apiCall, oldgenericApi } from '../../../common/apiconfig';
import Loader from '../../../common/loader'
import Tunnel from '../../../siteSrc/components/site/pir/dashboard/3dTunnel'
import { connect, useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';


const Dashboard = (props) => {
  const reduxdispatch = useDispatch();
  const [progress, setProgress] = useState(false)
  const [donutData, setDonutData] = useState("")
  const [columnChartData, setColumnChartData] = useState("")
  const [pieChartData, setPieChartData] = useState("")
  const [mapData, setMapData] = useState("")
  const [funnelData, setFunnelData] = useState("")
  const [funnelProgress, setFunnelProgress] = useState(false)
  const [funnelCount, setFunnelCount] = useState("")
  let reduxstateobj = useSelector(state => state);
  useEffect(() => {
    if (props.info && props.info === "Sponsor Order") {
      callComposeOrderAPi()
    } else {
      callComposeApi()
    }
    // callFunnelApi("*")
    reduxdispatch({ type: 'UpdateFormField', name: "funnelPatients", value: false })
  }, [])

  // const callFunnelApi = async (order) => {
  //   const { genderField = '', age = '', raceField = '', language = '', education = '', city = '', stateField = '', zip = '', countryField = null } = props
  //   let requestBody = {
  //     query: `query{
  //       getComposeFunnelDetailforSitewithFilter(studyId:"${props.data.studyId}",siteId:"${props.data.siteId}",patientGender:"${genderField != "" ? genderField.value : "*"}", patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}",order:"${order}")
  //         {
  //              nctId
  //               criteriaOrder
  //               criteriaText
  //               criteriaBaseOrder
  //               ieType
  //               criteriaIndex
  //               funnelCount
  //               matchCount
  //               totalPatientCount
  //         }
  //       }`
  //   }
  //   requestBody = JSON.stringify(requestBody);
  //   setFunnelProgress(true);
  //   let res = await genericApi('getComposeFunnelDetailforSitewithFilter', requestBody);
  //   setFunnelProgress(false);
  //   if (res) {
  //     if (res.data.getComposeFunnelDetailforSitewithFilter.length > 0) {
  //       var data = res.data.getComposeFunnelDetailforSitewithFilter
  //       var data1 = data.map((item, i) => { return [item.criteriaText + '(<b>' + item.ieType.toUpperCase() + '</b>)', parseInt(item.funnelCount), "#ff0000", item.criteriaBaseOrder] })
  //       // data1.unshift(['<b>Total Patients</b>', parseInt(data[0].totalPatientCount), "#ff0000", "0"])
  //       setFunnelData(data1)
  //       var count = data.reduce((acc, cv) => {
  //         return acc + parseInt(cv.funnelCount);
  //       }, 0)
  //       setFunnelCount(data[0].totalPatientCount)
  //     }
  //   }
  // }
  const callComposeOrderAPi = async () => {
    let requestBody = {
      query: `query {
    getComposeOrderDetails(orderId:"${reduxstateobj.form.orderdetails.orderId}", siteId:"${props.data.siteId}")
    {
         studyId
          siteId
          siteName
          siteCity
          siteState
          siteCountry
          patientId
          ehrPatientId
          patientFirstName
          patientMiddleName
          patientLastName
          patientEmail
          patientCity
          patientState
          patientZip
          patientCountryCode
          patientCountry
          patientGender
          patientAge
          percentMatch
          patient360
  }
}`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getComposeOrderDetails', requestBody);
    setProgress(false);
    if (res) {
      if (res.data && res.data.getComposeOrderDetails.length > 0) {
        var data = res.data.getComposeOrderDetails
        //subjects v/s gender
        let gender = data.map((item, i) => { return item.patientGender })
        let counts = {}
        await gender.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const datforDonut = Object.entries(counts);
        setDonutData(datforDonut)
        //subjects v/s age
        let age = data.map((item, i) => { return item.patientAge })
        let agecounts = {}
        await age.forEach(function (x) { agecounts[x] = (agecounts[x] || 0) + 1; });
        const dataforColumn = Object.entries(agecounts);
        var result = dataforColumn.map(arr => arr.map(item => Number(item)));
        setColumnChartData(result)
        //subjects v/s % match
        // var lfmatch = data.filter(i => parseInt(i.percentMatch) < 50)
        // var gfmatch = data.filter(i => (parseInt(i.percentMatch) >= 50 && parseInt(i.percentMatch) <= 70))
        // var gsmatch = data.filter(i => parseInt(i.percentMatch) > 70)
        var lfmatch = data.filter(i => (parseInt(i.percentMatch) >= 50 && parseInt(i.percentMatch) <= 80))
        var gfmatch = data.filter(i => (parseInt(i.percentMatch) > 80 && parseInt(i.percentMatch) <= 99))
        var gsmatch = data.filter(i => parseInt(i.percentMatch) == 100)

        setPieChartData([{ name: "(50-80)% Match", y: lfmatch.length }, { name: "(81-99)% Match", y: gfmatch.length }, { name: "100% Match", y: gsmatch.length }])
        //maps
        var countriesData = data.map((item, i) => { return item.patientCountryCode })
        let countries = [...new Set(countriesData)]
        var filteredcountries = countries.filter(function (el) {
          return el != null;
        });
        var newData = filteredcountries.map((country, i) => {
          let count = data.filter((item, i) => item.patientCountryCode === country).length
          return [country.toLowerCase(), count]
        })
        console.log(newData, "maps")
        setMapData(newData)
      } else {
        setDonutData([])
        setColumnChartData([])
        setMapData([])
        setPieChartData([])
      }
    }
  }

  const callComposeApi = async () => {
    const { genderField = '', age = '', raceField = '', language = '', education = '', city = '', stateField = '', zip = '', countryField = null } = props
    let requestBody = {
      query: `query {
      getComposeDetails(siteId:"${props.data.siteId}",studyId:"${props.data.studyId}", 
      patientGender:"${genderField != "" ? genderField.value : "*"}",patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}")
      {
           studyId
            siteId
            siteName
            siteCity
            siteState
            siteCountry
            patientId
            patientFirstName
            patientMiddleName
            patientLastName
            patientEmail
            patientCity
            patientState
            patientZip
            patientCountryCode
            patientCountry
            patientGender
            patientAge
            percentMatch
            patient360
      }
    }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getComposeDetails', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getComposeDetails.length > 0) {
        var data = res.data.getComposeDetails
        //subjects v/s gender
        let gender = data.map((item, i) => { return item.patientGender })
        let counts = {}
        await gender.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const datforDonut = Object.entries(counts);
        setDonutData(datforDonut)
        //subjects v/s age
        let age = data.map((item, i) => { return item.patientAge })
        let agecounts = {}
        await age.forEach(function (x) { agecounts[x] = (agecounts[x] || 0) + 1; });
        const dataforColumn = Object.entries(agecounts);
        var result = dataforColumn.map(arr => arr.map(item => Number(item)));
        setColumnChartData(result)
        //subjects v/s % match
        // var lfmatch = data.filter(i => parseInt(i.percentMatch) < 50)
        // var gfmatch = data.filter(i => (parseInt(i.percentMatch) >= 50 && parseInt(i.percentMatch) <= 70))
        // var gsmatch = data.filter(i => parseInt(i.percentMatch) > 70)
        var lfmatch = data.filter(i => (parseInt(i.percentMatch) >= 50 && parseInt(i.percentMatch) <= 80))
        var gfmatch = data.filter(i => (parseInt(i.percentMatch) > 80 && parseInt(i.percentMatch) <= 99))
        var gsmatch = data.filter(i => parseInt(i.percentMatch) == 100)

        setPieChartData([{ name: "(50-80)% Match", y: lfmatch.length }, { name: "(81-99)% Match", y: gfmatch.length }, { name: "100% Match", y: gsmatch.length }])
        //maps
        var countriesData = data.map((item, i) => { return item.patientCountryCode })
        let countries = [...new Set(countriesData)]
        var filteredcountries = countries.filter(function (el) {
          return el != null;
        });
        var newData = filteredcountries.map((country, i) => {
          let count = data.filter((item, i) => item.patientCountryCode === country).length
          return [country.toLowerCase(), count]
        })
        setMapData(newData)
      } else {
        setDonutData([])
        setColumnChartData([])
        setMapData([])
        setPieChartData([])
      }
    }
  }

  const onClickFunnel = async (event) => {
    if (props.app === "Site") {
      var order = funnelData.find(arr => arr.includes(event.point.name))
      const { genderField = '', age = '', raceField = '', language = '', education = '', city = '', stateField = '', zip = '', countryField = null } = props
      if (order[3] != '0') {
        let requestBody = {
          query: `query{
          getComposeDetailsFunnel(studyId:"${props.data.studyId}",siteId:"${props.data.siteId}",patientGender:"${genderField != "" ? genderField.value : "*"}",criteriaBaseOrder:"${order[3]}" patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}",order:"*")
            {
                   studyId
            siteId
            siteName
            siteCity
            siteState
            siteCountry
            patientId
            ehrPatientId
            patientFirstName
            patientMiddleName
            patientLastName
            patientEmail
            patientCity
            patientState
            patientZip
            patientCountryCode
            patientCountry
            patientGender
            patientAge
            percentMatch
            patient360
            }
          }`
        }
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await oldgenericApi('getComposeDetailsFunnel', requestBody);
        setProgress(false);
        if (res) {
          if (res.data.getComposeDetailsFunnel.length > 0) {
            reduxdispatch({ type: 'UpdateFormField', name: "studypatients", value: res.data.getComposeDetailsFunnel })
            reduxdispatch({ type: 'UpdateFormField', name: "studypatientstext", value: "Patients(" + order[0].substring(0, order[0].indexOf("(")) + ' ' + order[1] + ")" })
            reduxdispatch({ type: 'UpdateFormField', name: "funnelPatients", value: true })
            props.history.push('/site/studypatients')

          }
        }
      }
    } else {
      event.preventDefault()
    }
  }
  return (
    <>
      <Loader progress={progress} />
      <div className="row mb-3">
        <div className="col-md-6 mb-3"><Donut header="No of Subjects v/s Gender" data={donutData} /></div>

        <div className="col-md-6 mb-3">
          <Columnchart header="No of Subjects v/s Age" data={columnChartData} />
        </div>
        <div className="col-md-6 mt-3 mb-3">
          <Piechart header="No of Subjects v/s Percentage Match" data={pieChartData} />
        </div>

        {/* <div className="col-md-6 mt-3">
          <BulletChart/>
        </div> */}
        <div className="col-md-6 mt-3">
          <Maps text="Patients Count by Country" mapdata={mapData} />
        </div>
        {/* <div className="col-md-6 mt-3">
          {funnelProgress ? <div className="funnelCss"><CircularProgress disableShrink
            className="funnelCircle"
            value={90}
            size={100}
            thickness={1.5} /></div> :

            <Tunnel header={`<b>Total Patients</b> ` + `${funnelCount}`} data={funnelData} onClickFunnel={onClickFunnel} labels={true}/>
          }
        </div> */}
        <div className="col-md-6 mt-3 mb-3">
          <VariablePiechart header="Subjects Match v/s Ongoing Conditions" />
        </div>
        <div className="col-md-6 mt-3">
          <Bubblechart header="Conditions v/s Status(Mild,Moderate,Severe)" />
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
export default withRouter(connect(mapStateToProps, null)(Dashboard))